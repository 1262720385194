import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/app/components/navigation/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/app/components/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/frontend/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.1-canary.16_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/frontend/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.1-canary.16_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.1.1-canary.16_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.1.1-canary.16_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/styles/font.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
