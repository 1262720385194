"use client";

import { Button } from "@/components/ui/button";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center mt-20 lg:mt-32 space-y-4 text-xl">
      <h1>Oops! Something went wrong!</h1>
      <p>
        <Button className="hover:text-kick-red" onClick={reset}>
          Try again
        </Button>
      </p>

      {/* <pre>{error.message}</pre> */}
    </div>
  );
}
